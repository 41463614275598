@import "commons/normalize.css";
@import "commons/fonts.css";
/* MEGA TOP */
@import "commons/variables.css";
@import "commons/extends.css";
@import "commons/animations.css";

/* MEGA COMMON */
@import "commons/global.css";
@import "commons/header.css";
@import "commons/footer.css";

@import "commons/bio.css";
@import "commons/music.css";
